.nav-tabs {
    border: none;
}

.nav-tabs .nav-link {
    cursor: pointer;
    background: white;
    color: #2b2b2b;
    font-size: 16px;
    font-weight: 400;
    line-height: 100%;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border: 0px;
    border-bottom: 3px solid #ff6700 !important;
    color: #2b2b2b;
    font-weight: 500;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
    border: none;
}