.reset-password-page {
  background: #fff;
  font-family: "Poppins", sans-serif !important;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.reset-password-logo {
  max-height: 120px;
  margin-top: -130px;
}

.reset-password {
  background: #fff;
  margin: auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  min-width: 350px;
  padding: 50px 50px 43px;
}

.reset-password .head {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-password .head .company {
  font-size: 1.5em;
}

.reset-password .msg {
  text-align: center;
}

.reset-password .form input[type="text"].text {
  border: none;
  background: none;
  box-shadow: 0px 2px 0px 0px #fff;
  width: 80%;
  color: #fff;
  font-size: 0.8em;
  outline: none;
  margin-top: 20px;
}

.form-group input {
  margin-bottom: 15px;
  height: 45px;
  display: inline-block;
  line-height: normal;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #bdbdbd;
  padding: 10px 16px;
  border-radius: 5px;
}

.form-group textarea {
  margin-bottom: 15px;
  height: 90px;
  display: inline-block;
  line-height: normal;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #bdbdbd;
  padding: 10px 16px;
  border-radius: 5px;
}
.reset-password .form .text::placeholder {
  color: #fff;
  /* background-image: url(../images/icons8-user-24.png); */
  background-repeat: no-repeat;
}

.reset-password .form input[type="password"].password {
  border: none;
  background: none;
  box-shadow: 0px 2px 0px 0px #fff;
  width: 80%;
  color: #fff;
  font-size: 0.8em;
  outline: none;
  /* margin-bottom: 20px; */
  margin-top: 20px;
}

h6 {
  font-size: 14px;
}

.reset-password .form .password::placeholder {
  color: #fff;
  /* background-image: url(../images/icons8-password-24.png); */
  background-repeat: no-repeat;
}

.reset-password .form .btn-reset-password {
  background: none;
  text-decoration: none;
  background: #b79027;
  color: #fff;
  /* color: #2B2B2B;
      box-shadow: 0px 0px 0px 2px #2B2B2B; */
  border-radius: 5px;
  padding: 9px 1.25em;
  cursor: pointer;
  transition: 0.5s;
  border: none;
}

.header {
  background: #b79027;
  color: white;
  font-size: 30px;
  padding: 15px 10px;
}

.footer {
  padding: 30px 0px;
  text-align: center;
  background: #b79027;
  color: white;
  font-size: 30px;
}
