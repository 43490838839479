.article-size .modal-dialog {
  max-width: 900px;
  max-height: 746px;
  overflow-y: auto;
}

.customModal .modal-body {
  /* margin: 1rem 25px; */
  padding: 20px 20px 25px 25px;
  max-height: 70vh;
  overflow-y: auto;
}

.customModal .modal-footer {
  padding-top: 0px;
}

.customModal .modal-body::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
}

.customModal .modal-body::-webkit-scrollbar-track {
  background: transparent;
  /* Track background */
}

.customModal .modal-body::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  /* Scrollbar thumb color */
  border-radius: 4px;
  /* Rounded edges */
}

.customModal .modal-body::-webkit-scrollbar-thumb:hover {
  background: #888;
  /* Scrollbar thumb hover color */
}

.emailSentPopupHeader {
  font-family: "Poppins" !important;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
}

.emailSentPopupHeader::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: rgba(219, 219, 219, 1);
  position: absolute;
  bottom: -19px;
  left: 0;
}

.modal-header {
  position: relative;
}

.emailSentPopupBody .subjectDateWrap {
  margin-top: 28px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  font-family: "Poppins" !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: rgba(43, 43, 43, 1);
}

.emailSentPopupBody .contentTextWrap,
.emailSentPopupBody .subjectTextWrap {
  font-family: "Poppins" !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
}
.emailSentPopupBody .contentHeader {
  font-family: "Poppins" !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 8px;
}
