.modal-backdrop.show {
    opacity: 0.7;
}

.customModal .modal-content {
    border-radius: 10px;
}

.modal-backdrop.show {
    opacity: 0.7;
}

.customModal .modal-content {
    border-radius: 10px;
}


.customModal .modal-dialog {
    max-width: 600px;
}

.reduceWidth .modal-dialog {
    max-width: 320px;
}

.customModal .modal-header {
    border-bottom: 0px;
    padding-bottom: 0;
    padding-left: 25px;
}

.customModal .modal-title {
    padding-top: 10px;
    font-weight: 600;
}

.line {
    margin: 10px 0px;
    opacity: 10;
    border: 1px solid #DBDBDB;
}

.customModal .modal-body {
    padding: 1rem 25px;
    white-space: normal;
    /* Allow text to wrap to next line */
    word-wrap: break-word;
    /* Break long words */
    overflow-wrap: break-word;
    /* Break long words */
    word-break: break-word;
}

.customModal .modal-footer {
    padding: 1rem 25px;
}

.customModal .modal-footer {
    border-top: 0px;
    padding: 1.5rem;
}