/*** responsive css here ***/

@import url("http://fonts.cdnfonts.com/css/uber-move-text");

body {
  font-family: "Poppins" !important;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;600;700&display=swap");

img {
  max-width: 100%;
}

.custom_height {
  min-height: calc(100vh - 189px);
}

.trans_head a {
  color: unset;
  text-decoration-line: none;
}

.trans_head a:hover {
  color: unset;
  text-decoration-line: none;
}

.back-heading {
  display: flex;
  gap: 10px;
  align-items: end;
  cursor: pointer;
}

.image-text {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #2B2B2B;
}

.image-text-not-active {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #747474;
}

.image-view {
  position: relative;
  border-radius: 8px;
  border: 1px solid #DFDFDF;
  width: "90px";
}

.image-cross {
  position: absolute;
  top: 3px;
  right: 0px;
  cursor: pointer;
}

.title-heading {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.title {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.title2 {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.sub-title {
  font-size: 13px;
  font-weight: 400;
  color: #2B2B2B;
}

.article-heading {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.regular {
  font-size: 16px;
  font-weight: 400;
  color: #2b2b2b;
}

.btn-check:focus+.btn,
.btn:focus {
  box-shadow: unset !important;
}

.btn-gap {
  display: flex;
  gap: 12px;
  width: 100%;
}

.btn-black2,
.btn-black2:hover,
.btn-black2:active {
  border: 1px solid #2B2B2B;
  padding: 12px 30px;
  color: #2B2B2B;
  font-size: 14px;
  background-color: white;
  width: 100%;
}

.btn-orange2,
.btn-orange2:hover,
.btn-orange2:active {
  background: #ff6700;
  border: none;
  color: #fff;
  padding: 12px 35px;
  font-size: 14px;
  width: 100%;
}

.btn-orange2:disabled {
  background-color: gray;
  cursor: not-allowed;
}

.mian_content.active .content {
  padding-left: 0;
  padding-top: 0;
}

/*****************************************Start     login page****************************************/
.login_section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #ff6700 0%, #07858d 100%);
}

.login_box {
  background: #ffffff;
  box-shadow: 0px 2px 34px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 20px;
  max-width: 476px;
  margin: auto;
}

.image-upload {
  border: 1px dashed #FF6700;
  border-radius: 8px;
  background-color: rgba(253, 237, 218, 0.40);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 84px;
  cursor: pointer;
}

.image-border {
  border: 1px solid #EEEEEE;
  height: 179px;
  overflow: hidden;
  border-radius: 8px;
}

.image-upload-not-active {
  border: 1px dashed #DFDFDF;
  border-radius: 8px;
  background-color: rgba(253, 237, 218, 0.40);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 84px;
  cursor: not-allowed;
}

.login_box h2 {
  color: #172036;
  font-size: 35px;
}

.login_box p {
  color: #8d8484;
  font-size: 18px;
}

.login_box label {
  color: #2b1c1c;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.article-img input {
  opacity: 0;
  position: absolute;
  width: 180px;
  height: 180px;
  cursor: pointer;
}

.article-img img {
  border-radius: 20px;
  border-color: #000;
  width: 180px;
  height: 180px;
  border: 1px solid #c5bebe;
}

.user-image {
  border-radius: 0px;
}

.user-identify {
  text-align: center;
  color: #000000a3;
  font-size: 14px;
}

.sale_graph {
  min-height: calc(100vh - 183px);
}

.article-img {
  position: relative;
  width: 180px;
  height: 180px;
}

.login_box .form-group {
  text-align: left;
}

.disabled-fields {
  cursor: not-allowed;
}

.login_box .input100 {
  background: #f2f2f2 !important;
  border-radius: 100px;
  border: 0;
  padding: 0 47px 0 24px;
  height: 55px;
  font-weight: 400;
  color: #8d8484;
}

.login_box .form-control:focus {
  box-shadow: none;
  background-color: #f2f2f2;
}

.login_box .btn.login_btn {
  background: #0ab2b0;
  border-radius: 100px;
  color: white;
  font-size: 20px;
  font-weight: 600;
  min-width: 207px;
  min-height: 56px;
  line-height: 1;
}

.show_img {
  position: absolute;
  top: 70%;
  right: 22px;
  transform: translate(0, -50%);
  cursor: pointer;
}

.menu-days {
  opacity: 0%;
  margin-top: -65px;
}

/*****************************************End login page****************************************/

/*****************************************start header ****************************************/
.main_logo {
  border-right: 1px solid #e9e7e7;
  width: 275px;
  text-align: center;
  padding: 8px 0px 8px 9px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-around;
}

.header-logo-img {
  max-height: 70px;
}

.notification_dropdown .dropdown-toggle::after {
  content: unset;
}

.headerWrap .searchIconWrap {
  z-index: 3;
  left: 18.5px;
  top: 14.5px;
}

.headerWrap .inputWrap {
  padding-left: 48px !important;
}

.notification_dropdown h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #494040;
}

header .notification_dropdown .dropdown-menu p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 117%;
  margin-bottom: 0;
  color: #6c6c6c;
}

header .notification_dropdown .dropdown-menu {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 250px;
  overflow: auto;
}

header .notification_dropdown .dropdown-menu .dropdown-item:first-child {
  border-top: 0;
}

header .notification_dropdown .dropdown-menu .dropdown-item {
  padding: 15px 0 15px 51px;
  position: relative;
  display: block;
}

header .notification_dropdown .dropdown-menu .dropdown-item img {
  position: absolute;
  left: 0;
}

header .dropdown-menu {
  background: #2b2b2b;
  box-shadow: 0px 2px 34px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  border: 0;
}

header .dropdown-menu p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 9px;
  line-height: 100%;
  color: #fff;
}

header .dropdown-menu .dropdown-item {
  padding: 11px 0;
  border-top: 1px solid #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #fff;
}

header .dropdown-menu .dropdown-item:hover {
  background-color: transparent;
}

.profile_dropdown .dropdown-menu {
  min-width: 283px;
}

header .dropdown-menu {
  padding: 12px;
}

header .dropdown-menu h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #ff6700;
}

.login-logo-box img {
  width: 160px;
  filter: brightness(0.5);
}

header li {
  list-style: none;
  display: inline-flex;
  margin: 0 0px 0 18px;
}

.place-tag {
  padding: 5px;
  background-color: #ff6700;
  border-radius: 10px;
  color: white;
  display: inline-block;
  margin: 3px 3px;
}

.c-pointer {
  cursor: pointer;
}

.b-r-50 {
  border-radius: 50%;
}

.selected-itn-img {
  width: 85px;
  height: 85px;
  border-radius: 100px;
  object-fit: cover;
  border: 1px solid #d3d3d378;
  min-width: 85px;
}

.plus-ico .fa-2x {
  font-size: 2em;
  width: 85px;
  height: 85px;
  border: 1px solid lightgrey;
  border-radius: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: grey;
}

.carousel-item img {
  /* vertical-align: middle; */
  height: 250px;
  object-fit: contain;
  overflow: hidden;
  /* min-width: 100%; */
}

.carousel-item {
  text-align: center;
}

.carousal-bs .carousel-control-next,
.carousal-bs .carousel-control-prev {
  background: #ff6700;
  width: 29px;
  height: 29px;
  border-radius: 100px;
  padding: 7px;
  top: 50%;
  transform: translate(0px, -50%);
}

.label-place {
  font-size: 15px;
  color: grey;
}

.count {
  position: absolute;
  right: -1px;
  top: -1px;
  width: 16px;
  height: 16px;
  font-size: 11px;
  font-weight: 500;
  color: white;
  border-radius: 100px;
  background-color: #ff0101;
}

.user_profile {
  display: flex;
  width: 54px;
  height: 54px;
  overflow: hidden;
  border-radius: 100px;
  margin-right: 7px;
  background: #c4c4c4;
}

header .btn:focus {
  box-shadow: none;
}

header .dropdown-toggle::after {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

header {
  background-color: #fff;
  box-shadow: 0px 2px 34px rgba(0, 0, 0, 0.08);
}

.greyOutText {
  color: #dccece !important;
}

/*****************************************End header ****************************************/

/*****************************************Start Sidebar ****************************************/
.sidebar .active {
  background: #fff;
  /* border-left: 5px solid #f66701; */
}

.sidebar .active .sidebar_option {
  color: #b79027;
}

.sidebar .active .sidebar_option img {
  filter: unset;
}

.mian_content {
  min-height: calc(100vh - 86px);
}

.sidebar_option.active a {
  color: #b79027;
}

.sidebar_option {
  position: relative;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  font-size: 14px !important;
  white-space: nowrap;
}

.sidebar_option a {
  display: block;
  text-decoration-line: none !important;
  color: white;
}

.sidebar_option a[aria-expanded="true"] .down-arrow-side {
  transform: translate(0, -50%) rotate(180deg);
  transition: 0.2s ease;
}

.sidebar_option .down-arrow-side {
  position: absolute;
  right: 5px;
  font-size: 23px;
  top: 50%;
  transform: translate(0, -50%) rotate(-90deg);
  pointer-events: none;
  transition: 0.2s ease;
}

.textfeild-edit-icon {
  cursor: pointer;
  position: absolute;
  top: 39px;
  right: 22px;
}

.sub-list {
  padding: 2px 0px 0px 54px;
  margin-bottom: 0;
}

.sub-list li a {
  color: #ffffffad !important;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 8px;
  cursor: pointer;
  display: inline-block;
}

.sub-list li a.active {
  color: #fff !important;
  background-color: transparent;
  /* font-size: 16px !important; */
  border-left: 0;

  font-weight: 600;
}

.sidebar {
  background: #b79027;
  width: 290px;
  position: absolute;
  height: 100%;
  left: 0;
  z-index: 1026;
  transition: 0.5s;
  text-align: left;
  padding-top: 13px;
}

.sidebar_option {
  font-size: 18px;
  display: block;
  color: white;
  cursor: pointer;
  margin-bottom: 5px;
  padding: 12px 0px 12px 25px;
}

.clndr_icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  pointer-events: none;
  color: #b79027;
  cursor: pointer;
}

.pick-date {
  cursor: pointer;
}

.highcharts-legend-box,
.highcharts-legend {
  display: none !important;
}

.sidebar .active .sidebar_option {
  padding: 15px 0px 15px 20px;
}

.sidebar_option img {
  margin-right: 13px;
  filter: brightness(0) invert(1);
}

.barbtn {
  background: #b79027;
  color: white;
  font-size: 16px;
  min-width: 41px;
  height: 38px;
  border: 0;
  margin-left: 20px;
  display: none;
  border-radius: 4px;

  position: fixed;
  top: 29px;
  border-radius: 4px;
  z-index: 1025;
  left: 0;
}

.barbtn i {
  font-size: 20px;
}

.profilebtn .btn {
  background: #ff6700;
  border-radius: 100px;
  font-weight: 400;
  font-size: 16px;
  margin-top: 20px;
  color: #ffffff !important;
  padding: 12px 20px;
}

footer {
  border-top: 1px solid lightgrey;
}

/*****************************************End Sidebar ****************************************/

/*****************************************Start Dashboard ****************************************/
.content {
  padding-left: 287px;
  padding-top: 12px;
}

.mian_content {
  background-color: #e5e5e5;
  position: relative;
}

.count_box h2 {
  color: #2b1c1c;
  margin-bottom: 0;
  font-size: 35px;
  font-weight: 600;
}

.count_box p {
  color: #494040;
  font-size: 18px;
  margin-bottom: 0;
}

.sale_graph {
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 2px 34px rgb(0 0 0 / 8%);
  border-radius: 10px;
  text-align: left;
  padding: 14px 19px;
}

.count_box {
  background: #ffffff;
  box-shadow: 0px 2px 34px rgb(0 0 0 / 8%);
  border-radius: 10px;
  text-align: left;
  position: relative;
  padding: 25px 0px 25px 139px;
}

.count_box span {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translate(0, -50%);
}

.sale_graph h2 {
  color: #2b1c1c;
  font-size: 22px;
  margin-bottom: 40px;
  font-weight: 700;
}

.trans_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.transaction_box .input100 {
  background: #f2f2f2 !important;
  border-radius: 5px;
  border: 0;
  padding: 0 4px 0 47px;
  height: 48px;
  font-weight: 400;
}

.new_Itinerary .form-group,
.new_Itinerary form {
  text-align: left;
}

.new_Itinerary {
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 2px 34px rgb(0 0 0 / 8%);
  border-radius: 10px;
  text-align: left;
  padding: 14px 19px;
}

.age_range {
  margin: 35px 12px;
}

.nxtbtn_1 {
  background: #ff6700;
  border-radius: 100px;
  min-width: 152px;
  min-height: 50px;
  color: white !important;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  border: 0;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new_Itinerary .new_inineary_step2 .input100 {
  padding-left: 36px !important;
}

.new_inineary_step2 .count_tag {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background: #ff6700;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: white;
}

.new_inineary_step2 .fa {
  font-size: 24px;
  /* color: #f00c0c; */
  width: 45px;
  height: 45px;
  background: white;
  box-shadow: 2px 2px 10px #0000002e;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.new_inineary_step2 .show_img {
  left: 16px;
  right: unset;
}

.profile-btn {
  position: relative;
}

.change-profile-input {
  border-radius: 100px;
  padding: 10px 20px;
  width: 203px;
  opacity: 0;
  position: absolute;
  left: 80px;
  top: 19px;
}

.new_inineary_step2 .show_img i {
  font-size: 20px;
  color: grey;
}

.register_profile_image {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.previewProfilePic {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.item_list1 span {
  margin-left: 3px;
}

.register_profile_image input {
  position: absolute;
  opacity: 0;
  width: 100%;
  cursor: pointer;
  height: 100%;
}

.playerProfilePic_home_tile {
  width: 128px;
  height: 128px;
  object-fit: cover;
  border: none !important;
  padding: 10px;
  border-radius: 9px;
}

.upload_imgrow h4 {
  color: #2b1c1c;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5;
}

.upload_imgrow h4 span {
  font-size: 15px;
  color: grey;
  font-weight: 400;
}

.new_inineary_step2 h6 {
  white-space: nowrap;
  padding: 0 20px;
}

.changeorder_row button {
  border: 1px solid #ff6700;
  padding: 6px 11px;
  color: #ff6700;
  border-radius: 7px;
  font-size: 15px;
}

.inputtd_step2 {
  width: 100%;
}

.changeorder_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new_inineary_step2 .table tr,
.new_inineary_step2 .table tr:last-child {
  border-bottom: 0 !important;
}

.new_inineary_step2 .table tr td {
  padding: 0 !important;
  vertical-align: unset;
}

.recomm_places {
  display: flex;
}

.recomm_places h5 {
  color: #ff6700 !important;
  font-size: 16px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.modal-content .close {
  width: 32px;
  height: 32px;
  border: 0;
  border-radius: 100px;
}

.tag_span i {
  margin-left: 8px;
}

.tag_span {
  background: #ff67001a;
  border-radius: 100px;
  color: #ff6700 !important;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  cursor: pointer;
  border: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 12px;
  margin: 5px 6px 0 0;
}

.inputstep2.form-group {
  margin-top: 25px;
}

.newactivity_btn1 .btn {
  background: #ff6700;
  border-radius: 100px;
  color: white !important;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  border: 0;
  padding: 12px 14px;
  align-items: center;
  justify-content: center;
}

.newactivity_btn1 {
  background: #ff6700;
  border-radius: 100px;
  color: white !important;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  border: 0;
  padding: 12px 14px;
  align-items: center;
  justify-content: center;
}

.activityday {
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
}

.savebtn_tag {
  background: #ff6700;
  border-radius: 100px;
  min-width: 152px;
  min-height: 50px;
  color: white !important;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  border: 0;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputstep2_modal .input100 {
  height: 48px;
  font-size: 14px;
  margin-bottom: 5px;
}

.modal-content .close span {
  font-size: 25px;
  line-height: 1.2;
}

.recomm_places h5 i {
  margin-right: 5px;
  font-size: 25px;
}

.recomm_places button {
  color: #ff6700;
  font-weight: 500;
  width: 150px;
  margin-right: 20px;
  border-bottom: 1px solid #ff6700 !important;
  border-radius: 0;
  font-size: 15px;
}

.complex {
  margin-left: 20px;
}

span.uploaded-box i {
  background: none !important;
  box-shadow: none !important;
  width: 17px !important;
  color: #fff;
  height: 22px !important;
  font-size: 22px !important;
}

span.uploaded-box img {
  width: 150px !important;
  height: 100px !important;
  object-fit: cover;
}

.dlt-bt {
  position: absolute;
  right: -9px;
  top: -56px;
  font-size: 20px !important;
  border-radius: 50% !important;
  height: 40px;
  width: 40px;
  background: #ff6700 !important;
  border: none !important;
}

span.uploaded-box {
  position: relative;
  height: 100px;
  margin-right: 18px;
}

.time {
  margin-left: 20px;
  width: 105px !important;
}

.activity {
  width: auto;
}

.new_Itinerary .input100 {
  padding-left: 20px !important;
  background-color: white !important;
  border: 1px solid lightgray;
}

.rc-slider-handle {
  width: 19px !important;
  height: 19px !important;
  background-color: #ff6700 !important;
}

.rc-slider-track {
  background-color: #ff6700 !important;
}

.rc-slider-rail,
.rc-slider-track {
  height: 8px !important;
}

.rc-slider-tooltip-inner {
  min-width: 35px !important;
  background-color: #ff6700 !important;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-top-color: #ff6700 !important;
}

.transaction_box .input100:focus {
  box-shadow: none;
}

.transaction_box .srch_img1 {
  position: absolute;
  left: 19px;
  top: 50%;
  transform: translate(0, -50%);
}

table th {
  color: #494040;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 500;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9 !important;
  padding: 12px 17px 12px 17px !important;
}

table td {
  border: 0;
  color: #494040;
  font-size: 13px;
  font-weight: 400;
  vertical-align: middle;
  max-width: 170px;
  word-break: break-word;
}

.status_pending {
  color: #ff1d1d;
}

.transaction_box .table> :not(:first-child) {
  border-top: 0;
}

.status_config {
  color: #ff6700;
}

.page-item:first-child .page-link {
  background: #f0f0f0;
  border-radius: 100px !important;
  border: 0;
  color: #494040;
  min-width: 100px;
  text-align: center;
}

.page-item:last-child .page-link {
  background: #ff6700;
  border-radius: 100px !important;
  color: white !important;
  min-width: 100px;
  text-align: center;
  border: 0;
}

.details-icons {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
}

.page-link {
  padding: 0.375rem 0.75rem;
  color: #494040 !important;
  border: 0 !important;
  width: 38px;
  height: 38px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #ff6700 !important;
  border-color: #ff6700;
  border-radius: 100px;
}

.page-link span[aria-hidden="true"] {
  display: none;
}

.page-item:last-child .page-link .sr-only {
  position: unset;
  width: unset;
  height: unset;
}

.page-item:first-child .page-link .sr-only {
  position: unset;
  width: unset;
  height: unset;
}

.page-link:hover {
  background-color: #ff6700;
  border-color: #ff6700;
}

.page-item {
  margin: 0 2px !important;
}

.pagination {
  justify-content: end;
}

.footer_text {
  color: #494040;
  font-size: 13px;
  font-weight: 100;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-align: left;
}

.text-center.py-3 h4 {
  font-size: 16px;
}

/*****************************************toggle button gender****************************************/
.toggleGender {
  background-color: rgb(209, 240, 240) !important;
  border: none !important;
  border-radius: 20px !important;
  margin: 0.5rem !important;
  color: darkcyan !important;
}

/****************************************start botmanagement_section *******************************/
.detail_row1 h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 100%;
  color: #494040;
}

.detail_row1 p {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 100%;
  color: #494040;
  margin-bottom: 0;
}

.detail_row1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ebebeb;
  padding: 16px 0;
}

.sub-list li {
  list-style: none;
}

.read_more {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
  color: #4abaea;
}

.table tbody tr:last-child {
  border-bottom: 1px solid #e9e9e9 !important;
}

.table td {
  vertical-align: middle !important;
}

.trans_head p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #494040;
}

.trans_head h2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
  font-size: 17px;
  color: #494040;
  margin-top: 10px;
}

.trans_head .usersHeaderWrap {
  font-size: 16px;
  color: #2B2B2B;
}

.iti-div-box {
  display: flex;
  align-items: center;
  margin: 22px 0;
  position: relative;
}

.chat-messages-border {
  padding: 10px 10px 10px 10px !important;
}

.iti-clone {
  margin-left: 90%;
}

.form-control {
  font-size: 13px;
  position: relative;
}

.gift-modal {
  max-width: 500px;
  margin: 0 auto;
}

.plan-search select {
  border-radius: 5px;
  background-color: white;
}

.gift-icon {
  position: absolute;
  right: 23px;
  top: 35px;
  color: #f66701;
  cursor: pointer;
  font-size: 22px;
}

.plan-gift {
  color: #f66701;
  cursor: pointer;
  font-size: 22px;
}

.chat-status {
  position: relative;
}

.chat-status .fa {
  position: absolute;
  left: 15px;
  font-size: 10px;
  color: #f66701;
}

.has-search .form-control {
  padding-left: 2.375rem;
  font-size: 14px;
}

.edit-bt-input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-bt-input textarea {
  min-width: 135px;
  resize: none;
  border: 1px solid #b9b9b9 !important;
  border-radius: 5px;
  outline: none !important;
}

.edit-bt-input button {
  padding: 0 2px;
}

.edit-icon {
  font-size: 10px;
  color: #f66701;
  background: #f6670166;
  border-radius: 50px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

h5 {
  font-size: 17px;
}

.itinery-img img {
  width: 105px;
  height: 105px;
  object-fit: cover;
}

.togglebtn .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 18px;
}

.togglebtn .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.deleted-comment {
  opacity: 0.5;
}

.togglebtn .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.togglebtn .slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

.togglebtn input:checked+.slider {
  background-color: #ff6700;
}

.trigger-bt-box {
  position: absolute;
  right: 0;
  top: 0;
}

.trigger-bt-dis {
  position: absolute;
  right: 0;
  top: 70px;
}

.user-details {
  cursor: not-allowed;
}

.togglebtn input:focus+.slider {
  box-shadow: 0 0 1px #ff6700;
}

.togglebtn input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.togglebtn .slider.round {
  border-radius: 34px;
}

.togglebtn .slider.round:before {
  border-radius: 50%;
}

.itinery-img {
  margin-right: 20px;
}

.search-clear {
  position: relative;
}

.search-clear .clear-btn {
  position: absolute;
  right: 10px;
  font-size: 25px;
  top: 0px;
  color: #aaa;
  cursor: pointer;
}

.btn_title_row_backbutton {
  display: flex;
  gap: "10px";
  align-items: end;
}

.search-clear input {
  padding-right: 25px !important;
  width: auto;
}

.has-search .form-control {
  border-radius: 30px !important;
  max-width: 250px;
}

.itineary-table-box p {
  margin-bottom: 2px;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.700rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.trans_head .btn {
  background: #ff6700;
  border-radius: 100px;
  min-height: 41px;
  color: white !important;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trans_head .btn2 {
  background: #ff6700;
  border-radius: 6px;
  color: white !important;
  font-size: 13px;
  font-weight: 500;
  margin-top: 10px;
  padding: 0px 19px;
  letter-spacing: 1px
}

.btn-gap {
  display: flex;
  gap: 12px;
}

.trans_head .btn img {
  margin-right: 5px;
}

.profile_dropdown .dropdown-toggle {
  white-space: nowrap;
  background: transparent;
}

.selectMenu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  width: 63px;
  border-radius: 4px;
  box-shadow: 0px 4.092px 4.092px 0px rgba(0, 0, 0, 0.09);
  padding: 10px;
}

.menu-dropdown {
  position: absolute;
  right: 7%;
  z-index: 999999;
}

.required-image-error {
  color: #dc3545;
  font-size: 12px;
}

.selectMenu-btn {
  font-size: 12px;
  font-weight: 400;
  color: #2B2B2B;
  cursor: pointer;
}

/* footer {
    position: absolute;
    bottom: 0;
    z-index: 9999;
    left: 0;
    width: 80%;
    margin-left: 287px;
} */

label {
  font-size: 13px;
  font-family: "Poppins", sans-serif !important;
}

input {
  font-size: 14px;
}

h3 {
  font-size: 19px;
}

.couple-image {
  border: 2px solid #f66701;
}

.couple-image-user {
  border: 2px solid rgb(183, 144, 39);
}

.cropper-container {
  height: 500px;
  min-height: 36rem;
}

.imagePreviewContainerWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagePreviewContainerWrap .imageWrap {
  width: 70vh;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customModal {
  padding-left: 0 !important;
}

.imagePreviewBodyWrap .modal-content {
  min-height: 89vh;
}

.imagePreviewBodyWrap .modal-body {
  max-height: 75vh;
}

/******************************************end botmanagement_section ******************************/

@media (max-width: 1300px) {
  .edit_bot_img .btn {
    font-size: 14px;
    padding: 11px 9px;
    min-width: 100%;
  }

  .edit_bot_img {
    padding: 10px;
  }
}

@media (max-width: 1199px) {
  .profile_section .edit_bot_img {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 991px) {
  .inputtd_step2 {
    width: 70%;
  }

  .main_logo {
    border-right: 0;
    width: unset;
  }

  header {
    position: sticky;
    top: 0;
    z-index: 1025;
    background: white;
    box-shadow: 0px 2px 34px rgb(0 0 0 / 8%);
  }

  .overhidden {
    overflow: hidden;
  }

  .barbtn {
    display: inline-block;
  }

  .content {
    padding-left: 0;
  }

  .sidebar {
    left: -100%;
  }

  .sidebar.active {
    left: 0;
    overflow: hidden;
    top: 0;
    z-index: 1024;
  }

  .sidebar-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
  }

  .sidebar-overlay.active {
    z-index: 1023;
    overflow: hidden;
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width: 767px) {
  .edit_bot_img .btn {
    min-width: 170px;
    display: block;
    text-align: center;
    margin: auto;
  }

  .edit_bot_img {
    padding: 20px 10px;
  }

  .trans_head .btn.dltebtn1 {
    min-width: 96px;
    min-height: 36px;
    margin-left: 0px;
    font-size: 14px;
    margin-top: 5px;
  }

  .btn_title_row {
    flex-direction: column;
    align-items: start;
  }

  .trans_head {
    align-items: start;
  }

  .sale_graph h2 {
    font-size: 20px;
  }
}

@media (max-width: 575px) {
  .trans_head p {
    margin-bottom: 3px;
  }

  .action_btn {
    min-height: 48px;
  }

  .action_btn .btn {
    margin-bottom: 0 !important;
  }

  .sale_graph h2 {
    font-size: 18px;
  }

  .trans_head .btn.dltebtn1 {
    min-width: 90px;
    min-height: 31px;
    margin-left: 10px;
    font-size: 13px;
    margin-top: 5px;
  }

  .trans_head {
    padding-bottom: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .transaction_box .input100 {
    margin-top: 15px;
  }

  .btn_title_row {
    flex-direction: row;
    align-items: center;
  }

  .edit_bot_details td {
    font-size: 15px;
  }

  .edit_bot_details textarea.input101 {
    padding: 10px !important;
  }

  .input101 {
    border-radius: 10px !important;
  }

  .botmanagement_section.edit_bot_details .table img {
    width: unset !important;
  }

  .botmanagement_section .table h5 {
    font-size: 19px;
  }

  .botmanagement_section .table td:first-child {
    width: 120px !important;
    min-width: 120px !important;
  }

  /* .botmanagement_section .table img {
    width: 82px !important;
  } */

  .botmanagement_section .table p {
    font-size: 15px;
    display: block;
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MOB_COL1 .col-sm-5 {
    width: 40%;
  }

  .MOB_COL1 .col-sm-7 {
    width: 60%;
  }

  .amount_col1 {
    width: 100%;
  }

  .amount_input .form-group {
    width: 100%;
  }

  .edit_bot_modal h6 {
    text-align: left;
  }

  .page-item:last-child .page-link {
    min-width: 65px;
  }

  .page-link {
    padding: 7px 5px !important;
    font-size: 13px;
    width: 33px;
    height: 33px;
  }

  .page-item:first-child .page-link {
    min-width: 87px;
  }

  .footer_text {
    font-size: 13px;
    color: #2b2b2b;
  }

  .trans_head .btn {
    margin-bottom: 10px;
    font-size: 15px;
    min-width: 142px;
    min-height: 38px;
  }

  .count_box h2 {
    font-size: 27px;
  }

  .custom_height {
    min-height: 100%;
  }
}

/* LOGIN */

button:focus {
  outline: unset;
}

.login-page {
  background: #fff;
  font-family: "Poppins", sans-serif !important;
  display: flex;
  min-height: 100vh;
}

.login-logo {
  max-height: 120px;
  margin-top: -130px;
}

.login {
  color: #fff;
  background: #b79027;
  margin: auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 50px 50px 43px;
}

.login .head {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login .head .company {
  font-size: 1.5em;
}

.login .msg {
  text-align: center;
}

.login .form input[type="text"].text {
  border: none;
  background: none;
  box-shadow: 0px 2px 0px 0px #fff;
  width: 80%;
  color: #fff;
  font-size: 0.8em;
  outline: none;
  margin-top: 20px;
}

.login .form .text::placeholder {
  color: #fff;
  /* background-image: url(../images/icons8-user-24.png); */
  background-repeat: no-repeat;
}

.login .form input[type="password"].password {
  border: none;
  background: none;
  box-shadow: 0px 2px 0px 0px #fff;
  width: 80%;
  color: #fff;
  font-size: 0.8em;
  outline: none;
  /* margin-bottom: 20px; */
  margin-top: 20px;
}

h6 {
  font-size: 14px;
}

.login .form .password::placeholder {
  color: #fff;
  /* background-image: url(../images/icons8-password-24.png); */
  background-repeat: no-repeat;
}

.login .form .btn-login {
  background: none;
  text-decoration: none;
  background: #2b2b2b;
  color: #fff;
  /* color: #2B2B2B;
    box-shadow: 0px 0px 0px 2px #2B2B2B; */
  border-radius: 5px;
  padding: 9px 4em;
  cursor: pointer;
  transition: 0.5s;
  border: none;
}

/* .login .form .btn-login:hover {
    background: #2B2B2B;
    color: #fff;
    transition: 0.5s;
} */

/* CARD CSS */

.pricingTable {
  border: 1px solid #dbdbdb;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
  text-align: center;
  transition: all 0.4s ease-in-out 0s;
}

.pricing-plans .price-value .validity {
  font-size: 1rem;
  font-weight: 500;
}

.pricingTable:hover {
  border: 2px solid #ff6700;
  margin-top: -30px;
}

.pricingTable .pricingTable-header {
  padding: 30px 10px;
}

.pricingTable .heading {
  display: block;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 19px;
}

.pricingTable .pricing-plans {
  padding-bottom: 25px;
  border-bottom: 1px solid #d0d0d0;
  color: #000;
  font-weight: 500;
}

.pricingTable .price-value {
  color: #474747;
  display: block;
  font-size: 25px;
  font-weight: 800;
  line-height: 35px;
  padding: 0 10px;
}

.pricingTable .price-value span {
  font-size: 50px;
}

.pricingTable .subtitle {
  color: #1c1d1d;
  display: block;
  font-size: 15px;
  margin-top: 15px;
  font-weight: 100;
}

.flex_between {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.pricingTable .pricingTable-sign-up {
  padding: 25px 0;
}

.pricingTable .btn-block {
  width: 50%;
  margin: 0 auto;
  color: #000000 !important;
  border: 1px solid #0000003f !important;
  background: #fff !important;
  border: 1px solid transparent;
  padding: 10px 5px;
  border-radius: 5px;
  transition: 0.3s ease;
}

.pricingTable .btn-block:after {
  font-family: "Montserrat", sans-serif;
  padding-left: 10px;
  font-size: 15px;
}

.pricingTable:hover .btn-block {
  color: #ff6700 !important;
  border: 1px solid #ff6700 !important;
}

.pricingTable-sign-up .btn-block:hover {
  background: #ff6700 !important;
  color: #fff !important;
}

@media screen and (max-width: 990px) {
  .pricingTable {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .pricingTable {
    margin: 0 0 30px 0;
  }
}

.pick-date {
  font-size: 14px;
  border: 1px solid lightgrey;
  padding: 11px 11px;
  border-radius: 9px;
  background: #ed050500;
  color: #4e4e4e;
}

/* Loader */

.loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #000000bf;
  top: 0;
  left: 0;
  z-index: 9999;
}

.imgContainer {
  display: flex;
}

.loader div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* ================= pagination ========================= */
.pagination {
  display: inline-flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.pagination a {
  padding: 1px 13px !important;
  color: #ff6700 !important;
  font-size: 15px;
  font-weight: 400 !important;
  cursor: pointer;
}

.pagination .active a {
  background: #ff6700 !important;
  color: #fff !important;
  cursor: pointer;
}

.pagination .previous {
  font-size: 15px;
  text-align: center;
  padding: 0px !important;
  height: 24px;
  cursor: pointer;
}

ul.pagination {
  margin: 10px 15px !important;
  align-items: center;
}

.pagination .disabled a {
  cursor: not-allowed;
}

/* ====================================================== */

.contact-name {
  color: #ff6700;
  cursor: pointer;
  margin-bottom: 1px;
}

.message-main-receiver .avatar-icon img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 100%;
}

.contact-message {
  cursor: pointer;
  color: #ff6700 !important;
}

.article-comments {
  margin-top: 20px;
  border: solid 1px #00000034;
  border-radius: 20px;
  padding: 15px;
}

/* ============Chat================= */
.chat_outer {
  height: calc(100% - 121px);
  width: 100%;
  overflow: auto;
}

.message-main-receiver,
.message-main-sender {
  padding: 3px 9px !important;
}

.receiver .avatar-icon {
  position: absolute;
  left: 0;
}

.receiver {
  position: relative;
  width: auto !important;
  padding: 4px 10px 7px 55px !important;
  border-radius: 10px 10px 10px 0;
  font-size: 12px;
  margin-left: 10px;
  word-wrap: break-word;
  display: inline-block;
}

.message-main-receiver {
  padding: 3px 9px !important;
  max-height: 400px;
  overflow: auto;
}

.avatar-icon {
  width: 45px;
  height: 45px;
  margin: auto;
  display: inline-block;
  border-radius: 100px;
  overflow: hidden;
}

.heading-avatar-icon img {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.receiver .avatar-icon {
  position: absolute;
  left: 0;
}

.message-name {
  font-size: 15px;
  font-weight: 500;
  color: #345161;
}

.message-time {
  margin: 0 !important;
  margin-right: 5px !important;
  font-size: 13px;
  text-align: right;
  font-weight: 300;
  color: #577484;
}

.dlte_cmnt {
  font-size: 20px;
  color: #ff2121;
  margin-left: 8px;
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
  cursor: pointer;
}

.mesage_text {
  max-width: 550px;
  display: inline-block;
  line-height: 1.6;
  font-size: 17px !important;
  color: #2b2b2b !important;
  background: #f1d1aa;
  padding: 12px 28px 21px 16px;
  border-radius: 6px;
  margin-top: 5px;
  color: #7e7e7e;
  font-size: 15px;
}

.membership {
  background-color: #e9ecef;
  opacity: 1;
  font-size: 13px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 100%;
  padding: 0.375rem 0.75rem;
  color: #495057;
}

.btn-orange,
.btn-orange:hover,
.btn-orange:active {
  background: #ff6700;
  border: none;
  color: #fff;
  font-size: 11px;
}

.btn-orange2,
.btn-orange2:hover,
.btn-orange2:active {
  background: #ff6700;
  border: none;
  color: #fff;
  padding: 12px 35px;
  font-size: 14px;
}

.btn-orange2:disabled {
  background-color: gray;
  cursor: not-allowed;
}

.btn-orange1,
.btn-orange:active {
  background: #ff6700;
  color: #fff;
  font-size: 11px;
  border-radius: 0px !important;
}

.btn-black,
.btn-black:hover,
.btn-black:active {
  background: #2b2b2b;
  border: none;
  color: #fff;
  font-size: 11px;
}

.btn-black2,
.btn-black2:hover,
.btn-black2:active {
  border: 1px solid #2B2B2B;
  padding: 12px 30px;
  color: #2B2B2B;
  font-size: 14px;
  background-color: white;
}

.select-admin {
  width: 210px;
  height: 32px;
  margin-left: 20px;
  border-radius: 6px;
  background: white;
}

.private-gallery {
  display: flex;
  flex-wrap: wrap;
}

.private-photos img {
  margin-right: 15px;
  width: 150px !important;
  height: 150px !important;
  object-fit: cover;
}

.private-photos button:focus {
  outline: 0;
  border: 0;
}

.maingarry canvas {
  height: 150px !important;
  width: 150px !important;
  object-fit: cover;
}

.imgsize {
  width: 100%;
  height: 123px !important;
  object-fit: cover;
}

.private-photos .react-blur {
  max-width: 220px;
  max-height: 220px;
}

.upload-content span {
  background: #e4e4e4;
  padding: 12px;
  border-radius: 5px;
  position: absolute;
  top: -41px;
  left: 11px;
}

.upload-content {
  position: relative;
}

.upload-content i {
  cursor: pointer;
}

.file-down {
  color: #ff6700;
  font-size: 25px;
  cursor: pointer;
}

.profile-opt .dropdown-toggle::after {
  content: unset;
}

.profile-drop {
  font-size: 25px;
}

.idetify-images.row [data-rmiz-wrap="visible"],
.idetify-images.row [data-rmiz-wrap="visible"] img {
  width: 100%;
}

.idetify-images.row [data-rmiz-wrap="visible"] img {
  object-fit: initial;
  height: 370px;
}

.doc-identity {
  height: 280px;
  cursor: pointer;
}

.msg-data {
  margin-top: 150px;
}

.membership-btn-section {
  display: flex;
  align-items: end;
}

.membership-btn-section button {
  padding: 9px 15px;
}

.user-membership-modal .modal-title.h4 {
  color: #2b2b2b;
  font-weight: 500;
}

.user-membership-modal .modal-content {
  padding: 5px 15px;
}

.user-membership-modal select.form-select {
  padding: 10px 5px;
  border-radius: 5px;
  background: none;
  border: 1px solid #000;
  margin-top: 5px;
  color: #000;
}

.user-membership-modal .modal-header {
  padding: 15px 0 15px 5px;
}

@media (max-width: 1199px) {
  .recent-chat {
    height: 680px !important;
  }

  .online_1,
  .ofline_1 {
    right: 2px;
  }

  .message_header1 .online_1 {
    right: 10px;
  }
}

@media (max-width: 991px) {

  .online_1,
  .ofline_1 {
    right: 11px;
  }

  .time-meta {
    width: 100%;
    display: block;
  }

  .sideBar-body .col-sm-2 {
    width: 10%;
  }

  .col-sm-10.sideBar-main {
    width: 90%;
  }
}

@media (max-width: 575px) {
  .message_header1 .online_1 {
    right: 15px;
  }

  .online_1,
  .ofline_1 {
    right: 4px;
  }

  .avatar-icon {
    width: 39px;
    height: 39px;
  }

  .sideBar-body .col-sm-2 {
    width: 16%;
  }

  .col-sm-10.sideBar-main {
    width: 84%;
  }

  .chat_app {
    padding: 0 13px;
  }

  .col-sm-9.col-xs-9.sideBar-name {
    width: 100%;
  }

  .col-sm-3.col-xs-3.pull-right.sideBar-time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: row-reverse;
    text-align: left !important;
    float: left !important;
  }

  .sideBar-time h6 {
    text-align: left;
  }

  .time-meta {
    text-align: right;
  }

  .message_header1 {
    flex-direction: column;
    margin: 7px 6px;
    position: unset;
    align-items: start;
    background-color: #9bcaf3;
  }

  .mesage_text {
    font-size: 13px;
  }

  .message-main-receiver,
  .message-main-sender {
    padding: 3px 9px !important;
  }

  .message_header1 .pull-right.sideBar-time {
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }

  .message_header1 .pull-right.sideBar-time .info_button img {
    width: 27px !important;
  }

  .hirebtn {
    font-size: 15px;
    padding: 3px 15px;
  }

  .info_1 {
    width: 156px;
    font-size: 14px;
    text-align: center;
    right: 0;
    top: 28px;
    border-radius: 11px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

/* ============Chat CSS================= */

.chat-online {
  color: #34ce57;
}

.chat-offline {
  color: #e4606d;
}

.start_chat {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #b79027;
  white-space: nowrap;
}

.chat-messages {
  display: flex;
  flex-direction: column;
  height: 428px;
  overflow-y: scroll;
}

.search-inp {
  position: relative;
}

.cross-arrow {
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translate(0, -50%);
  color: #b79027;
  cursor: pointer;
}

.flex-grow-1 {
  font-family: "Poppins", sans-serif;
}

.chat-message-left,
.chat-message-right {
  display: flex;
  flex-shrink: 0;
  max-width: 70%;
}

.recent-chat {
  height: 566px;
  overflow-y: auto;
}

.chat-message-left {
  margin-right: auto;
}

.chat-message-right {
  flex-direction: row-reverse;
  margin-left: auto;
  max-width: 70%;
}

.fa-paperclip {
  font-size: 24px;
  padding: 8px 12px;
  cursor: pointer;
  pointer-events: none;
}

.upload-document .custom-input {
  position: absolute;
  left: 0;
  width: 43px;
  height: 40px;
  opacity: 0;
  cursor: pointer !important;
}

.upload-document {
  position: relative;
}

.chat-message-left .flex-shrink-1 {
  background: #f1d1aa !important;
  border-radius: 0 12px 12px 12px !important;
  padding: 7px 33px 16px 10px;
  text-align: left;
}

.send-bt-box {
  background: #ff6700;
  color: #fff;
}

.list-group-item {
  cursor: pointer;
}

a.active.list-group-item {
  background: #b79027;
  color: white !important;
}

.chat-message-right img,
.chat-message-left img {
  min-width: 40px;
}

.chat-message-right .flex-shrink-1 {
  background: #c4a772 !important;
  border-radius: 12px 0 12px 12px !important;
  padding: 7px 10px 16px 33px;
  text-align: right;
}

.outer-div {
  min-height: calc(100vh - 269px);
}

.chat-message-right .flex-shrink-1 p {
  color: white;
}

.login .form .mb-5 {
  margin-bottom: 2.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.error {
  color: #ff6700;
  font-size: 15px;
}

.no-resize {
  resize: none;
}

.form-control {
  color: #747474;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  border-radius: 8px;
}

.float-right {
  float: right;
}

.crossIconField {
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
}

.circleIconField {
  position: absolute;
  top: 9px;
  left: 9px;
  cursor: pointer;
  z-index: 2;
}

.padding-left-30 {
  padding-left: 30px;
}

.custom-column-gap {
  column-gap: 20px;
}

.font-weight-500 {
  font-weight: 500;
}

.MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
}