.textfield-label-container {
    display: flex;
    gap: 2px;
    align-items: center;
}

.textfield-label {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    color: #333;
}

.textfield-required {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    color: #333;
}

.textfield-wrapper {
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: center;
    border-radius: 10px;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1.5px solid #ccc;
    position: relative;
}

.textfield-wrapper.textarea {
    padding-bottom: 28px;
}

.textfield-focus {
    border-color: #007bff;
    box-shadow: 0 0 5px #007bff;
}

.textfield-error {
    border-color: #dc3545;
    box-shadow: 0 0 5px #dc3545;
}

.textfield-disabled {
    cursor: not-allowed;
    background-color: #e9ecef;
}

.textfield-input {
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    color: #747474;
    background-color: transparent;
    border: none;
    padding: 0;
    outline: none;
    font-family: "Poppins";
    font-weight: 400;
}

.textfield-textarea {
    resize: none;
}

.textfield-icon {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    color: #ccc;
    cursor: pointer;
}

.textfield-icon:hover {
    color: #6c757d;
}

.textfield-counter {
    position: absolute;
    bottom: 5px;
    right: 20px;
    font-size: 12px;
    color: #6c757d;
}

.textfield-helpertext {
    margin-top: 10px;
    font-size: 12px;
    color: #dc3545;
    font-family: Poppins;
    font-weight: 500;
}